@import '../../variables/variables.less';

@base-unit: 16px;

.tables {
	.tables__title {
		margin-bottom: 0;
	}

	.tables__text {
		font-weight: normal;
		margin-bottom: 0;
		color: @Black-v2;

		&.tables__text--lines {
			strong,
			span {
				display: block;
			}

			span {
			}
		}
	}

	&--simplified {
		width: 100%;
		border-color: transparent;
		border-style: none;

		@media @mobile {
			display: block;

			thead,
			tbody,
			tfoot {
				display: block;
			}
		}

		h6,
		.h6 {
			margin: 0;
			color: @Black-v2;
			font-size: 18px;
			font-weight: normal;
		}

		th,
		td {
			padding: @base-unit (@base-unit * .5);
			vertical-align: middle;
			text-align: center;
			border-bottom: 1px solid @Grey-12;
			position: relative;
			background-clip: padding-box;

			&.va-bottom {
				vertical-align: bottom;
			}

			&.va-top {
				vertical-align: top;
			}

			&.va-middle {
				vertical-align: middle;
			}

			&.noborder-left {
				border-left: transparent;
			}

			&.noborder-bottom {
				border-bottom: transparent;
			}

			&.noborder-top {
				border-top: transparent;
			}

			@media @desktop {
				&.highlight {
					border-left: 1px solid #ff7800;
					border-right: 1px solid #ff7800;

					&-top {
						border-top: 1px solid #ff7800;
					}

					&-bottom {
						border-bottom: 1px solid #ff7800;
					}
				}
			}
		}

		thead {
			@media @mobile {
				position: -webkit-sticky;
				position: sticky;
				top: 0;
				z-index: 3;
			}

			tr {
				@media @mobile {
					display: flex;
					flex-wrap: wrap;
				}

				th,
				td {
					text-align: center;
					border-color: transparent;
					vertical-align: top;
					padding: (@base-unit * 1.25) (@base-unit * .5);

					@media @mobile {
						background: @White;
						padding: 0;
					}
				}
			}

			.icon-product {
				margin-bottom: 8px;
			}
			.h6 {
				margin-bottom: 6px;
			}

			.link {
				margin-top: 10px;
				a {
					color: @Blue-v2;
					font-size: 16px;

					&:hover,
					&:active {
					  color: #1B61B5;
					  text-decoration: none;
					  border-bottom: 1px solid #1B61B5;
					}
				}
			}
		}

		tbody {
			tr {
				@media @mobile {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
				}

				td,
				th {
					@media @desktop {
						min-height: 56px;
					}
				}

				th {
					text-align: left;
				}

				td {
					min-width: 160px;

					@media @tablet {
						min-width: 120px;
					}
					@media @mobile {
						flex: 1;
						min-width: 0;
					}
				}
			}
		}

		tfoot {
			tr {
				@media @mobile {
					display: flex;
					flex-wrap: wrap;
				}

				td,
				th {
					padding: (@base-unit * 2) (@base-unit);
					border-color: transparent;
					vertical-align: bottom;

					@media @mobile {
						padding: 0;
						position: relative;
						z-index: 9;
						background: @White;
					}

					.buttons-wrapper {
						display: block;
					}

					.text-wrapper {
						height: 42px;
					}

					.footer-text {
						display: block;
						margin-top: 10px;
						font-weight: normal;
						font-size: 14px;
					}
				}
			}
		}

		.tables__headline {
			@media @mobile {
				flex: 1;
				padding: (@base-unit * 1.25) (@base-unit * .5);
			}
		}

		.tables__footline {
			@media @mobile {
				flex: 1;
				padding: (@base-unit * 1.25) (@base-unit * .5);
			}
		}

		.tables__desc {
			@media @mobile {
				flex: 1 1 100%;
			}

			span {
				display: block;
			}
		}

		&.tables--col-2 {
			.tables__headline {
				flex-basis: 50%;
				max-width: 50%;
			}
		}

		&.tables--col-3 {
			.tables__headline {
				flex-basis: 33.3%;
				max-width: 33.3%;
			}
		}

		&.tables--col-4 {
			.tables__headline {
				flex-basis: 25%;
				max-width: 25%;
			}
		}
	}

	&--product {
		width: 100%;
		border-color: transparent;
		border-style: none;

		@media @mobile {
			display: block;

			thead,
			tbody,
			tfoot {
				display: block;
			}
		}

		h3,
		.h3 {
			margin: 0;
		}

		th,
		td {
			padding: (@base-unit * 1) (@base-unit * .5);
			vertical-align: middle;
			border-bottom: 1px solid @Grey-12;
			position: relative;
			text-align: left;

			&:nth-child(2) {
				border-left: 1px solid @Grey-12;
			}

			&:last-child {
				border-right: 1px solid @Grey-12;
			}

			&.va-bottom {
				vertical-align: bottom;
			}

			&.va-top {
				vertical-align: top;
			}

			&.va-middle {
				vertical-align: middle;
			}

			@media @desktop {
				&.noborder-left {
					border-left: transparent !important;
				}

				&.noborder-right {
					border-right: transparent !important;
				}

				&.noborder-bottom {
					border-bottom: transparent !important;
				}

				&.noborder-top {
					border-top: transparent !important;
				}

				&.highlight {
					border-left: 1px solid #ff7800 !important;
					border-right: 1px solid #ff7800 !important;

					&-top {
						border-top: 1px solid #ff7800 !important;
					}

					&-bottom {
						border-bottom: 1px solid #ff7800 !important;
					}
				}
			}
		}

		thead {
			tr {
				@media @tablet, @mobile {
					display: flex;
					flex-wrap: wrap;
					margin: 0 -1px;
				}

				th {
					vertical-align: top;
					border-color: transparent;
					padding: (@base-unit * 1.25);
					background: @White;
					background-clip: padding-box;

					&:nth-child(2) {
						border-left: 1px solid @Grey-12;
					}

					&:first-child {
						background: #f4f3fa;
						background-clip: padding-box;
						border-color: transparent;
						padding: (@base-unit * 1.25) (@base-unit * .5);

						@media @desktop {
							&.white {
								background: @White;
							}
						}
					}

					@media @tablet, @mobile {
						background: @White;
						padding: 0;

						&.tables__desc {
							display: none;
						}
					}
				}

				&.emptyrow {
					th,
					td {
						padding: (@base-unit * .5);

						&:first-child {
							border-color: transparent;
						}
					}
				}
			}
		}

		tbody {
			tr {
				@media @tablet, @mobile {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					margin: 0 -1px;
				}

				td,
				th {
					@media @desktop {
						min-height: 40px;
					}
				}

				th {
					width: 33.3%;
					text-align: left;
					background-color: #f4f3fa;
					background-clip: padding-box;

					@media @desktop {
						&.white {
							background-color: @White;
						}
					}
					@media @tablet, @mobile {
						text-align: center;
					}
				}

				td {
					width: 66.6%;
					background-color: @White;
					background-clip: padding-box;
					text-align: center;

					@media @tablet, @mobile {
						flex: 1;
						min-width: 0;
					}
				}

				&:last-child {
					td,
					th {
						border-bottom: 1px solid @Grey-12;
					}
				}

				&.emptyrow {
					th,
					td {
						padding: (@base-unit * .5);

						&:first-child {
							border-color: transparent;
						}
					}
				}
			}
		}

		tfoot {
			tr {
				@media @tablet, @mobile {
					display: flex;
					flex-wrap: wrap;
					margin: 0 -1px;
				}

				td,
				th {
					padding: (@base-unit * 2) (@base-unit);
					vertical-align: top;
					background-color: @White;
					background-clip: padding-box;

					&:first-child {
						background-color: transparent;
						border-color: transparent;
					}

					@media @tablet, @mobile {
						padding: 0;

						&.tables__desc {
							background-color: @White;
							background-clip: padding-box;
							text-align: center;
							padding: (@base-unit * 1.25) (@base-unit * .5);
							border-left: 1px solid @Grey-12;
							border-right: 1px solid @Grey-12;
						}
					}
					@media @mobile {
						border-bottom: 1px solid @Grey-12;
						border-left: 1px solid @Grey-12;
						border-right: 1px solid @Grey-12;

						&.noborder-bottom {
							border-bottom: 1px solid @Grey-12;
						}
					}
				}

				td {
					text-align: center;

					@media @desktop {
						border-left-color: transparent;
					}
				}

				th {
					@media @mobile {
						border-bottom: 1px solid @Grey-12;
					}
				}

				&.emptyrow {
					@media @mobile {
						display: none;
					}

					th,
					td {
						padding: (@base-unit * .5);

						&:first-child {
							border-color: transparent;
						}

						@media @tablet, @mobile {
							&.tables__desc {
								display: none;
							}
						}
					}
				}

				&:last-child {
					td {
						border-bottom: 1px solid @Grey-12;

						&:first-child {
							background-color: transparent;
							border-color: transparent;
						}
					}

					th {
						@media @tablet, @mobile {
							border-bottom: none;
						}
					}
				}
			}
		}

		.tables__headline {
			text-align: center;

			@media @tablet, @mobile {
				flex: 1;
				padding: (@base-unit * 1.25) (@base-unit * .5);
				border-top: 1px solid @Grey-12;
			}
		}

		.tables__footline {
			@media @tablet, @mobile {
				flex: 1;
				padding: (@base-unit * 1.25) (@base-unit * .5);
			}
			@media @mobile {
				flex: 1 1 100%;
				padding: (@base-unit * 1.5) (@base-unit * 1.25);
			}

			.vue-action-box,
			.try-box {
				margin: (@base-unit * .7) 0 0 0;

				.actionbox-container,
				.try-box-container {
					box-shadow: none;
					border: none;
					max-width: 100%;
					width: auto;
					padding-bottom: 0;

					@media @mobile {
						padding-top: 0;
					}

					.box {
						width: auto;
						padding: 0;
					}
				}

				/* hotfix  FIXME - too much height */
				.labelActive {
					flex: auto;
				}

				@media @mobile {
					margin: 0;
					height: auto;

					.form-select-container {
						position: relative;
						top: 0;
					}
				}
			}

			.try-box {
				.slot {
					font-size: (@base-unit * 2.225);
					font-weight: 800;
				}

				.try-box-container {
					padding: 0 0 37px 0;

					@media @tablet, @mobile {
						padding: 0;
					}
				}
			}
		}

		.tables__hidden {
			display: none;

			&.is-active {
				display: table-row;

				@media @tablet, @mobile {
					display: flex;
				}
			}
		}

		.tables__desc {
			@media @tablet, @mobile {
				flex: 1 1 100%;
				background-color: #f8f8fc;
				border-left: 1px solid @Grey-12;
				border-right: 1px solid @Grey-12;
			}
		}

		.tables__productIcons {
			display: flex;
			align-items: center;

			img {
				margin-right: @base-unit * .5;
			}

			.tables__text {
				flex: 1;

				strong,
				span {
					margin-right: @base-unit * .3;
				}
			}

			@media @tablet, @mobile {
				justify-content: center;
				display: inline-flex;
				text-align: left;
			}
		}

		.tables__text {
			&.tables__text--tooltip {
				display: flex;
				align-items: center;

				@media @tablet, @mobile {
					justify-content: center;
					display: inline-flex;
					text-align: left;
				}

				span,
				.text__tooltip {
					margin-right: @base-unit;
					flex: 1;
				}
			}
		}

		.tables__tooltip {
			margin-left: auto;
			width: @base-unit;
			height: @base-unit;
			background: url("../../i/v2/components/info.svg") center center no-repeat;
			background-size: cover;
			cursor: pointer;
			border: none;

			&:hover,
			&:focus {
				background: url("../../i/v2/components/info-hover.svg") center center no-repeat;
				outline: none;
			}

			@media @tablet, @mobile {
				margin-left: @base-unit;
			}
		}

		.tables__textWithTooltip {
			display: flex;
			align-items: center;

			.tables__text {
				flex: 1;
				margin-right: @base-unit;
			}

			@media @tablet, @mobile {
				justify-content: center;
				display: inline-flex;
				text-align: left;
			}
		}

		.tables-product {
			display: inline-flex;
			color: inherit;
			text-align: left;

			&__icon {
				margin-right: @base-unit * .5;

				img {
					width: @base-unit * 3.5;
				}
			}

			.product-icon {
				margin-right: @base-unit;
			}

			&__content {
				flex: 1 1 auto;
			}

			&__name {
				margin: 0;
			}

			&__info {
				margin-bottom: @base-unit * .5;
				display: block;
				min-height: @base-unit;
			}

			&__platform {
				display: flex;
				align-items: center;
				margin-bottom: @base-unit;

				img {
					width: @base-unit;
					margin-right: @base-unit * .5;
				}
			}

			&__more {
				color: #261c6a;
				font-size: @base-unit * .75;
				line-height: 1.33;
				font-weight: 600;
				letter-spacing: .8px;
				text-transform: uppercase;
				border-bottom: 1px solid #261c6a;
			}

			&:hover {
				text-decoration: none;
				color: inherit;

				.tables-product__more {
					border-color: transparent;
				}
			}

			@media @tablet, @mobile {
				flex-direction: column;
				align-items: center;
				text-align: center;

				&__icon {
					margin: 0 0 (@base-unit * .75) 0;

					img {
						width: @base-unit * 3;
					}
				}

				.product-icon {
					margin: 0 0 (@base-unit * .75) 0;
				}

				&__platform {
					justify-content: center;
				}
			}
			@media @mobile {
				&__info,
				&__platform,
				&__more {
					display: none;
				}
			}
		}

		&.tables--centered {
			.tables-product {
				flex-direction: column;
				align-items: center;
				text-align: center;

				&__icon {
					margin: 0 0 (@base-unit * .75) 0;

					img {
						width: @base-unit * 2;
					}
				}

				.product-icon {
					margin: 0 0 (@base-unit * .75) 0;
				}

				&__platform {
					justify-content: center;
				}
			}
		}

		&.tables--col-2 {
			td {
				width: 25%;
			}

			.tables__desc {
				width: 50%;
			}
		}

		&.tables--col-3 {
			td {
				width: 22.2%;
			}
		}

		&.tables--col-4 {
			td {
				width: 16.6%;
			}

			tbody {
				th {
					width: 25%;
				}
			}
		}
	}

	&--sticky {
		thead {
			@media @tablet, @mobile {
				position: -webkit-sticky;
				position: sticky;
				top: 0;
				z-index: 3;
			}

			tr {
				@media @tablet, @mobile {
					position: -webkit-sticky;
					position: sticky;
					top: 0;
					z-index: 3;
				}

				th {
					position: -webkit-sticky;
					position: sticky;
					top: 0;
					z-index: 3;

					@media @desktop {
						&.highlight-top {
							&:before {
								content: "";
								width: 100%;
								height: 1px;
								background: #ff7800;
								position: absolute;
								top: -1px;
								left: 0;
							}
						}
					}
				}
			}
		}

		tfoot {
			tr {
				td {
					position: relative;
					z-index: 9;

					@media @desktop {
						&.highlight-bottom {
							&:before {
								content: "";
								width: 100%;
								height: 1px;
								background: #ff7800;
								position: absolute;
								bottom: -1px;
								left: 0;
							}
						}
					}
				}
			}
		}
	}

	&-foot {
		&__moneyback {
			margin-bottom: @base-unit * 1.25;

			.money-back__item {
				padding-top: 0;
				margin: 0;
			}

			.money-back--large {
				.money-back__item {
					padding-top: 0;
					margin: 0;
				}
			}

			@media @tablet, @mobile {
				.money-back {
					justify-content: center;
				}
			}
		}

		&__more {
			margin-bottom: @base-unit;

			@media @desktop {
				margin-bottom: @base-unit * 2.75;
			}
		}

		&__help {
			font-size: @base-unit * .85;
			line-height: 1.71;
			font-weight: 400;
		}
	}

	&__afterTable {
		text-align: center;
		margin-top: @base-unit * 2.75;

		.tables__moneyback {
			margin-bottom: @base-unit * 1.25;

			.money-back__item {
				padding-top: 0;
				margin: 0;
			}

			@media @tablet, @mobile {
				.money-back {
					justify-content: center;
				}
			}
		}
	}
}

.table {
	width: 100%;
	border-color: transparent;
	border-style: none;

	@media @mobile {
		display: block;

		thead,
		tbody,
		tfoot {
			display: block;
		}
	}

	th,
	td {
		padding: @base-unit (@base-unit * .5);
		vertical-align: middle;
		text-align: center;
		border-top: 1px solid @Grey-12;
		position: relative;
		background-clip: padding-box;

		&.va-bottom {
			vertical-align: bottom;
		}

		&.va-top {
			vertical-align: top;
		}

		&.va-middle {
			vertical-align: middle;
		}

		&.noborder-left {
			border-left: transparent;
		}

		&.noborder-right {
			border-right: transparent;
		}

		&.noborder-bottom {
			border-bottom: transparent;
		}

		&.noborder-top {
			border-top: transparent;
		}
	}

	thead {
		@media @mobile {
			position: -webkit-sticky;
			position: sticky;
			top: 0;
			z-index: 3;
		}

		tr {
			@media @mobile {
				display: flex;
				flex-wrap: wrap;
			}

			th,
			td {
				text-align: center;
				border-color: transparent;
				border-bottom: hidden;
				padding: (@base-unit * 1.25) (@base-unit * .5);

				@media @mobile {
					background-color: @White;
					flex: 1;
				}
			}
		}
	}

	tbody {
		tr {
			@media @mobile {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}

			th {
				text-align: left;
				font-weight: normal;
				padding: @base-unit (@base-unit * .5);

				@media @mobile {
					flex: 1 1 100%;
					background-color: #f8f8fc;
				}
			}

			td {
				min-width: 160px;
				padding: @base-unit (@base-unit * .5);

				@media @mobile {
					flex: 1;
					min-width: 0;
				}
			}
		}
	}

	tfoot {
		tr {
			@media @mobile {
				display: flex;
				flex-wrap: wrap;
			}

			td,
			th {
				padding: (@base-unit * 2) (@base-unit);
				border-color: transparent;

				@media @mobile {
					padding: 0;
					position: relative;
					z-index: 9;
					background-color: @White;
				}
			}
		}
	}

}
