@import "../../variables/variables.less";
@import "../../components/facelift/tables.less";
@import 'vue-universal-actionbox.less';

@compTable-colName-width: 462px;
@compTable-colValue-width: 201px;


  #help-me-choose-tool {
  	width: 100%;
    margin: 0 auto 2.5rem auto;
    padding: 0;
    background-color: #f5f6fa;
    border: none;
    margin-top: -150px;
    text-align: center;
    outline: none;

    &.expanded {
        padding-bottom: 0px;
    }

    .main-headline {
        margin-top: 0px;
        margin-bottom: 24px;
    }

    .h2sub {
        margin-bottom: 32px;
        font-size: 18px;
    }

    .hmct-categories {
        min-height: 127px;
        margin-top: 32px;
        outline: none;
        position: relative;
        top: 1px;

        &:active {
            outline: none;
        }
        &:focus {
            outline: none;
        }
        a {
            &:hover, &:focus, &:active {
                text-decoration: none;
            }
        }
        .row {
            display: flex;
            flex-direction: row;
            padding: 0 0 1rem 0;
        }
        a {
            cursor: default;
        }
        .item {
            position: relative;
            width: 100%;
            padding: 39px 24px 35px;
            border-radius: 3px;
            box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
            background-color: #ffffff;
            overflow: hidden;
            transition: all 200ms ease-in;
            align-self: inherit;
            display: flex;
            flex-direction: column;
            height: 100%;
            &:not(.inactive) {
                bottom: -1rem;
            }

            h3 {
                margin: 0 0 auto 0;
                position: relative;
                font-size: 20px;
                font-family: 'AVGSans_Medium',Verdana,sans-serif;
                height: 100%;
                &.smaller {
                    font-size: 16px;    //used together with Latte cond if text is too long in some locales
                }
            }

            .button-wrapper {
                margin-top: 1rem;
                .button {
                    margin-bottom: 0;
                }
            }

            &.inactive {
                padding: 29px 24px 24px;
                //margin-top: 10px;
                cursor: pointer;
                height: 100%;
                h3 {
                    font-family: 'AVGSans_Light',Verdana,sans-serif;
                    margin-bottom: 0;
                }
            }
            &:hover {
                //margin-top: 0px;
                margin-top: -10px;
                margin-bottom: 10px;
                //padding-top: 39px;
                &:not(.inactive) {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
    .hmct-content {
        position: relative;
        .hmct-content-item {
            display: none;
            background: #fff;
        }
        .hmct-level-controller {
            margin-top: 60px;
        }
        .hmct-slider-wrapper {
            padding: 80px 80px;
            border-radius: 3px;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
            position: relative;

            p {
                margin-bottom: 12px;
                font-size: 20px;
            }

            &:before {
                display: block;
                width: 45px;
                height: 45px;
                content: " ";
                background: #fff;
                position: absolute;
                bottom: -20px;
                left: ~"calc(50% - 23px)";
                box-shadow: 14px 10px 16px 0px rgba(0, 0, 0, 0.05);
                transform: rotate(45deg);
            }
            //slider
            .ui-slider {
                width: 650px;
                height: 4px;
                background: #BBC1CC;
                margin: 0px auto;
                border: none;
                .ui-slider-handle {
                    width: 64px;
                    height: 24px;
                    border-radius: 20px;
                    top: -11px;
                    border: none;
                    margin-left: -33px;
                    cursor: pointer;
                    &:focus {
                        outline: none;
                    }
                    &:active {
                        border:none;
                    }
                }
                .ui-slider-range {

                    cursor: pointer;
                }
                .tooltip {
                    font-family: 'Roboto';
                    display: block;
                    position: absolute;
                    padding: 6px 8px;
                    background: #1E222A;
                    border-radius: 2px;
                    font-size: 11px;
                    font-weight: bold;
                    color: #FFFFFF;
                    top: -50px;
                    left: 50%;
                    transform: translateX(-50%);
                    &:before {
                        display: block;
                        content: " ";
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        background: #1E222A;
                        bottom: -3px;
                        left: ~"calc(50% - 4px)";
                        transform: rotate(45deg);
                    }
                }
            }

            &.hmct-slider-wrapper-protection {
                .ui-slider {
                    .ui-slider-handle {
                        background: #008941 url('../i/other/arrows.svg') no-repeat center;
                    }
                    .ui-slider-range {
                        background-color: #008941;
                    }
                }
            }
            &.hmct-slider-wrapper-privacy {
                .ui-slider {
                    .ui-slider-handle {
                        background: #e51603 url('../i/other/arrows.svg') no-repeat center;
                    }
                    .ui-slider-range {
                        background: #e51603;
                    }
                }
            }
            &.hmct-slider-wrapper-performance {
                .ui-slider {
                    .ui-slider-handle {
                        background: #249efc url('../i/other/arrows.svg') no-repeat center;
                    }
                    .ui-slider-range {
                        background: #249efc;
                    }
                }
            }

            .level-navigation {
                display: flex;
                justify-content: space-around;
                margin-top: 24px;
                a {

                    text-decoration: none;
                    &:hover {
                        text-decoration: none;
                    }
                    .level {
                        width: 224px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        position: relative;
                        cursor: pointer;
                        &:before {
                            display: none;
                            content: " ";
                            width: 10px;
                            height: 10px;
                            position: absolute;
                            top: -31px;
                            left: ~"calc(50% - 5px)";
                            border-radius: 50%;
                        }
                        h4 {
                            font-size: 20px;
                            font-family: 'Roboto';
                            letter-spacing: -0.6px;
                        }
                        .h4sub {
                            font-size: 16px;
                            max-width: 192px;
                            color: #6D768D;
                            font-family: 'Roboto';
                        }
                        span {
                            width: 30px;
                            height: 30px;
                            background: transparent;
                            position: absolute;
                            top: -40px;
                        }
                        &.active {
                            h4 {
                                font-family: 'Roboto';
                                font-size: 24px;
                            }
                            .h4sub {
                                color: #1E222A;
                                font-size: 20px;
                                font-family: 'Roboto';
                            }
                        }

                    }
                }
                &-protection {
                    .level-item {
                        &:before {
                            background: #008941;
                        }
                    }
                }
                &-privacy {
                    .level-item {
                        &:before {
                            background: #f8abb9;
                        }
                    }
                }
                &-performance {
                    .level-item {
                        &:before {
                            background: #91cefd;
                        }
                    }
                }
                }

        }
        .hmct-content-wrapper {
            padding: 120px 100px 80px 120px;
            @media @tablet {
                padding: 120px 80px 80px 80px;
              }

            @-webkit-keyframes fadeIn {
                from { opacity: 0; top: 50px; }
                  to { opacity: 1; top: 0px;}
            }
            @keyframes fadeIn {
                from { opacity: 0; top: 50px;}
                  to { opacity: 1; top: 0px;}
            }

            .hmct-product {
                display: none;
                transition: all 300ms ease-in;

                .row {
                    justify-content: flex-start;
                }

                &.selected {
                    display: block;
                    -webkit-animation: fadeIn 0.3s;
                    animation: fadeIn 0.3s;
                }

                h3 {
                    font-size: 32px;
                    line-height: 1.17;
                    margin-bottom: 64px;
                    span {
                        font-family: 'AVGSans_Medium',Verdana,sans-serif;
                    }
                }

                .product-name {
                    margin: 16px 0 85px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    .product-icon {
                        display: block;
                        margin-right: 24px;
                    }
                    h2 {
                        margin: 0px;
                        font-family: 'AVGSans_Medium',Verdana,sans-serif;
                    }
                }

                .features {
                    margin-top: 40px;
                    margin-bottom: 56px;
                    padding-right: 40px;
                    .item {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 24px;
                        h4 {
                            margin-top: 0px;
                            font-size: 24px;
                            font-family: 'AVGSans_Medium',Verdana,sans-serif;
                            letter-spacing: -0.6px;
                        }
                        p.description {
                            font-size: 16px;
                        }
                        .item-image {
                            min-width: 64px;
                          margin-right: 20px;
                        }
                    }
                }

                .plus-more {
                    font-size: 24px;
                }
                .list-tick {
                    list-style-type: none;
                    margin:40px 0 30px;
                    padding: 0px;
                    padding-right: 40px;
                    li {
                        padding-left: 32px;
                        margin-bottom: 0;
                        font-size: 20px;
                        font-weight: 400;
                        span {
                            color: #000;
                            font-weight: bold;
                        }
                    }
                    &.list-tick-green {
                        li {
                            background: url('../i/other/tick-dark.svg') no-repeat left top 5px;
                        }

                    }
                }

                &.hmct-product-performance-advanced {
                    .list-tick {
                        margin-top: 20px!important;
                    }
                }

                .more-features {
                    font-size: 20px;
                    margin-left: 33px;
                    text-decoration: none;
                    border-bottom: 2px dashed #2276D9;
                    padding-bottom: 2px;
                    color: @Blue-v2;
                    &:hover,
                    &:active {
                      color: #1B61B5;
                      border-bottom: 2px dashed #1B61B5;
                    }
                }

                &.hmct-product-performance-complete {
                    .more-features {
                        margin-left: 0px!important;
                    }
                }
                &.hmct-product-privacy-complete {
                    .more-features {
                        margin-left: 0px!important;
                    }
                }
                &.hmct-product-protection-complete {
                    .more-features {
                        margin-left: 0px!important;
                    }
                }

                .table-wrapper {
                    display: flex;
                    justify-content: center;
                }

                .tables {
                    &--simplified {
                        tbody {
                            th, td {
                                @media @desktop, @tablet {
                                    &.highlight {
                                        background-color: rgba(34,118,217,0.1);
                                        border-left: none;
                                        border-right: none;
                                    }
                                }
                            }
                        }

                        thead,
			            tfoot {
                            th, td {
                                @media @desktop, @tablet {
                                    &.highlight {
                                        border-left: none;
                                        border-right: none;
                                        border-top: none;
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .included-products {
                    margin-top: 40px;
                    .item {
                        margin-bottom: 30px;
                        display: flex;
                        flex-direction: row;
                        .included-product-icon {
                            margin-right: 20px;
                        }
                        .included-product-content {
                            h4 {
                                font-family: 'AVGSans_Medium',Verdana,sans-serif;
                                font-size: 20px;
                                margin-bottom: 32px;
                                margin-top: 2px;
                            }
                            .list-tick {
                                margin: 0px;
                                li {
                                    font-size: 16px;
                                    &:last-of-type {
                                        margin-bottom: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
                //actionbox adjustments
                .vue-action-box {
                    height: auto;
                }
                .col-md-5 {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                }
                .actionbox {
                    &.has-toggler {
                        margin-top: 32px;
                    }
                    width: 340px;

                    .money-back-guarantee {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        margin-top: 30px;
                        img {
                            margin-right: 10px;
                        }
                        span {
                            font-size: 14px;
                            letter-spacing: -0.42px;
                            color: #5d707b;
                        }
                    }
                    .form-select-container {
                        .form-select-arrow {
                            &.disabled {
                                &::after {
                                    content: none;
                                }
                            }
                        }
                    }
                }
                .actionbox-container {
                    width: 316px;
                    border: 1px solid #dfe4ec;
                    border-radius: 3px;
                    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
                    .vue-action-box .bottom-text {
                        position: static;
                    }
                }
                .vue-action-box .bottom-text {
                    position: static;
                }
            }
        }
    }

    //buttons
    .btn {
        display: inline-block;
        font-weight: normal;
        &:hover {
            text-decoration: none;
        }
    }

    .btn-square {
        border-radius: 4px;
    }

    .btn-medium {
        padding: 12px 23px 12px 50px;
        font-size: 16px;
        letter-spacing: -0.39px;
        @media @tablet {
            padding: 8px 8px 8px 50px;
          }
        @media (min-width: 1056px) and (max-width: 1300px) {
            padding: 8px 8px 8px 50px;
        }
    }

    .btn-blue {
        color: #2a7de1;
        border: solid 1px #2a7de1;
    }

    .btn-win {
        background: url('../i/platform-icons/hmct_tool/win-blue.svg') no-repeat 18px center;
    }
    .btn-mac {
        background: url('../i/platform-icons/hmct_tool/mac-blue.svg') no-repeat 18px center;
    }

    //helpers
    .no-margin {
        margin: 0px;
    }

    .mt60 {
        margin-top: 60px;
    }

    .js-android & {
        display: none!important;
    }
    .js-ios & {
        display: none!important;
    }

    .ds-none {
        display: none;
    }
    .ds-block {
        display: block;
    }
}


@media only screen and (max-width: 1024px) {
    #help-me-choose-tool {
        display: none;
    }
}

.actionbox-free {
    text-align: center;
    margin-top: 17px;
    .actionbox-container {
        padding: 30px;
    }
    .headline {
        margin-bottom: 16px;
        span {
            font-family: 'AVGSans_Light',Verdana,sans-serif;
            font-size: 16px;
            color: #000000;
        }
    }
    .icons {
        margin-bottom: 24px;
        img {
            margin: 0 4px;
        }
    }
    .price {
        margin-bottom: 31px;
        margin-top: 15px;
        span {
            font-size: 34px;
            font-family: 'AVGSans_Light',Verdana,sans-serif;
            color: #1e222a;
            letter-spacing: -0.62px;
        }
    }
    .price-additional-message {
        margin-bottom: 32px;
        span {
            font-size: 13px;
            letter-spacing: -0.44px;
            color: #5c707b;
            font-family: 'AVGSans_Book',Verdana,sans-serif;
        }
    }
    .btn {
        margin-bottom: 8px;
    }
    .button-additional-message {
        span {
            font-size: 13px;
            font-family: 'AVGSans_Book',Verdana,sans-serif;
            letter-spacing: -0.44px;
            color: #5c707b;
        }
    }
}

.js-ios {
    .banner-0 {
        min-height: 500px!important;
        padding-top: 140px!important;
        background-repeat: no-repeat!important;
        background-position: center top!important;
        background-color: #1a0a0b!important;
      }

      @media (max-width: 767px) {
        .banner-0 {
          padding-top: 70px!important;
        }
      }

      @media (max-width: 479px) {
        .banner-0 {
          background-repeat: no-repeat!important;
          background-position: center center!important;
        }
      }
}
.js-android {
    .banner-0 {
        min-height: 500px!important;
        padding-top: 140px!important;
        background-repeat: no-repeat!important;
        background-position: center top!important;
        background-color: #1a0a0b!important;
      }

      @media (max-width: 767px) {
        .banner-0 {
          padding-top: 70px!important;
        }
      }

      @media (max-width: 479px) {
        .banner-0 {
          background-repeat: no-repeat!important;
          background-position: center center!important;
        }
      }
}

.en-ww, .en-us, .en-eu, .en-gb, .en-ca, .en-au, .en-nz, .en-in, .en-za, .en-se, .en-dk {
    .comparison-table {
        .table-footer {
            .product-col {
                .btn-medium {
                    height: auto !important;
                }
            }
        }
    }
}


#help-me-choose {
    @media @mobile {
        display: none;
      }

    .ds-none {
        display: none;
        visibility: hidden;
    }

    span.line {
        display: block;
        content: " ";
        width: 100%;
        height: 3px;
        bottom: 0px;
    }
    span.line-1 {
        background-color: #008941;
    }
    span.line-2 {
        background-color: #e51603;
    }
    span.line-3 {
        background-color: #249efc;
    }

    .card {
        background: @White;
        border: 1px solid @Grey-10;
        text-align: center;
        filter: drop-shadow(0px 20px 50px rgba(30, 34, 42, 0.1));
        width: 100%;

        @media @tablet, @desktop {
        margin-bottom: 88px;
        }

        .content {
            background: @White-v2;
            padding: 64px 40px 64px 40px;

            p {
                margin-bottom: 0;
                font-size: 20px;
            }
        }
        .tabs {
            background: @White;
            padding: 38px 10px 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            border-top: 1px solid @Grey-10;

            .tab {
              width: 100%;
              padding: 0 8px;

              .button {
                margin-bottom: 48px;
              }
            }

            p {
                font-weight: 700;
                color: @Black-v2;
                font-size: 20px;
            }

            a {
              &.tabs-link {
                display: block;
                flex-grow: 1;

                &:hover {
                  text-decoration: none;
                }
                &.focus {
                  text-decoration: none;
                }
              }
            }

            img {
                margin-bottom: 12px;
            }

            .button {
                span {
                    &::after {
                        content: url("../../../i/components/cta-down.svg");
                        width: 24px;
                        height: 24px;
                        margin-left: 8px;
                        margin-top: -4px;
                    }
                }
            }
        }
    }
}


.fr-fr, .fr-ca, .fr-be, .fr-ch, .de-de, .de-ch {
    #help-me-choose {
      .tab {
        p {
          height: 90px;
            @media @tablet {
              height: 100px;
            }
        }
      }
    }
  }

  .pl-pl, .pt-br {
    #help-me-choose {
        .tab {
          p {
              @media @tablet {
                height: 90px;
              }
          }
        }
      }
  }
