@import 'style-guide/components/platform-switcher.less';
@import 'style-guide/components/help-me-choose-tool-v2.less';

//overwriting settings from the hmct.less
#help-me-choose-tool {
		margin-top: 0;
		margin-bottom: 0;
		display: block;
	}
//overwriting settings from the hmct.less

#backLink {
	p {
		margin-top: 30px;
	}
	.js-backlink {
		display: none;
	}
}

.buttons {
	.ios {
		a:not(.button) {
			color: #ffffff;
			text-decoration: underline;
		}
		.button-app-store {
			&.white {
				width: 192px;
			}
		}
	}
}

html.js-ios {
	.banner-0 {
		background-size: cover;
	}

	.other-platform {
		display: none;
	}
}

.challenger {display: none;}

.challenger-link {
	display: inline-block;
	padding-top: 80px;
}

html.js-android {
	.android-platform {
		display: block;
	}
	.other-platform {
		display: none;
	}
}

html.js-mac,
html.js-pc {
	.android-platform {
		display: none;
	}

	.other-platform {
		display: block;
	}
}

.banner-0 {
	background-size: cover;
	.js-platform-switch {
		padding-bottom: 20px;
	}
}

.js-pc .banner-0 .js-platform-switch, .js-mac .banner-0 .js-platform-switch {
	padding-bottom: 0px!important;
}
.js-pc .banner-0, .js-mac .banner-0 {
	padding-top: 172px!important;
}

.js-pc .banner-0 .buttons,.js-mac .banner-0 .buttons {
	margin-bottom: 25px;
}

/* logo fix */
.col-sni-10-ref-43 {
	padding-top:10px;
}
